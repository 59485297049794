
import { Component, Vue } from 'vue-property-decorator'
import { WaterQualityDetail } from '../../types/waterQuality.d'
import echarts from 'echarts'

@Component({
  name: 'DataAnalyse'
})
export default class DataAnalyse extends Vue {
  private paramList: Array<{ icon: string; name: string; value: string }> = [
    {
      icon: 'shuiwei2',
      name: '水位(m)',
      value: 'waterLevel'
    },
    {
      icon: 'shuiwen2',
      name: '水温(℃)',
      value: 'temperature'
    },
    {
      icon: 'zhuodu2',
      name: '浊度(NTU)',
      value: 'turbidity'
    },
    {
      icon: 'rongjieyang2',
      name: '溶解氧(mg/L)',
      value: 'dissolvedOxygen'
    },
    {
      icon: 'andan2',
      name: '氨氮(mg/L)',
      value: 'ammoniaNitrogen'
    },
    {
      icon: 'diandaolv2',
      name: '电导率(μS/cm)',
      value: 'conductivity'
    },
    {
      icon: 'ph2',
      name: 'pH',
      value: 'ph'
    }
  ]

  private deviceData = {}
  private searchInfo: { type: string; dateRange: Array<string> } = {
    type: '6',
    dateRange: []
  }

  private selectTime = 0
  private pickerOptions = {
    // 选中日期后会执行的回调
    onPick: (date: {maxDate: any; minDate: any}) => {
      this.selectTime = date.minDate.getTime()
      if (date.maxDate) {
        this.selectTime = 0
      }
    },
    disabledDate: (time: any) => {
      if (this.selectTime !== 0) {
        const dayTime = 29 * 24 * 3600 * 1000
        const minTime = this.selectTime - dayTime
        const maxTime = this.selectTime + dayTime
        return (time.getTime() < minTime || time.getTime() > maxTime)
      }
    }
  }

  private echart: any

  get deviceId () {
    return this.$route.params.deviceId as string
  }

  // 0-ph值,1-氨氮(mg/L),2-电导率（μS/cm）,3-溶解氧（mg/L）,4-水温℃,5-浊度（NTU）,6- 水位（cm）
  get typeList () {
    const arr = new Map<string, string>()
    arr.set('6', '水位(m)')
    arr.set('4', '水温(℃)')
    arr.set('5', '浊度(NTU)')
    arr.set('3', '溶解氧(mg/L)')
    arr.set('1', '氨氮(mg/L)')
    arr.set('2', '电导率(μS/cm)')
    arr.set('0', 'pH')
    return arr
  }

  // 0-ph值,1-氨氮(mg/L),2-电导率（us/cm）,3-溶解氧（mg/L）,4-水温℃,5-浊度（NTU）,6- 水位（cm）
  get typeColor () {
    const arr = new Map<string, string>()
    arr.set('6', 'rgba(19, 177, 244, 1)')
    arr.set('4', 'rgba(19, 177, 244, 1)')
    arr.set('5', 'rgba(90, 112, 220, 1)')
    arr.set('3', 'rgba(24, 144, 255, 1)')
    arr.set('1', 'rgba(0, 189, 156, 1)')
    arr.set('2', 'rgba(47, 194, 90, 1)')
    arr.set('0', 'rgba(248, 204, 0, 1)')
    return arr
  }

  $refs!: {
    echart: any;
  }

  created () {
    this.initTime()
    this.getData()
  }

  mounted () {
    window.addEventListener('resize', this.resize)
  }

  destroyed () {
    window.removeEventListener('resize', this.resize)
  }

  initTime () {
    const date = new Date()
    const val = date.getFullYear() + '-' + this.addZero(date.getMonth() + 1) + '-' + this.addZero(date.getDate())
    this.searchInfo.dateRange = [val, val]
  }

  addZero (val: number) {
    return val < 10 ? '0' + val : String(val)
  }

  getData (): void {
    if (this.searchInfo.dateRange && this.searchInfo.dateRange[0]) {
      const title = this.typeList.get(this.searchInfo.type) as string
      const color = this.typeColor.get(this.searchInfo.type) as string
      this.$axios.get(this.$apis.waterQuality.selectWaterQualityDataList, {
        deviceId: this.deviceId,
        type: this.searchInfo.type,
        startTime: this.searchInfo.dateRange[0],
        endTime: this.searchInfo.dateRange[1]
      }).then((res: WaterQualityDetail) => {
        this.deviceData = res.deviceData
        this.draw(title, [color], res.reportData.dataTimeList || [], res.reportData.dataList || [])
      })
    } else {
      this.$message.error('请选择查询日期范围')
    }
  }

  draw (title: string, color: Array<string>, xData: Array<string>, yData: Array<string>) {
    this.$nextTick(() => {
      this.echart = echarts.init(this.$refs.echart)
      const name = title.indexOf('(') > 0 ? title.substring(0, title.indexOf('(')) : title
      const option: any = {
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          top: 80,
          left: 10,
          right: 30,
          bottom: 0,
          containLabel: true
        },
        title: {
          text: title,
          textStyle: {
            fontSize: 18,
            fontWeight: 400,
            color: '#000000'
          }
        },
        legend: {
          icon: 'rect',
          top: 40,
          left: 0,
          itemWidth: 20,
          itemHeight: 1,
          itemGap: 5,
          textStyle: {
            color: '#8C8C8C'
          }
        },
        backgroundColor: 'transparent',
        color: color,
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: {
              boundaryGap: true
            }
          },
          axisLine: {
            lineStyle: {
              color: '#DDDDDD'
            }
          },
          axisLabel: {
            show: true,
            margin: 15,
            textStyle: {
              color: '#999999'
            }
          },
          data: xData
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false,
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.45)'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.15)'
            }
          }
        },
        series: [{
          name: name,
          type: 'line',
          symbolSize: 0,
          itemStyle: {
            normal: {
              borderWidth: 2
            }
          },
          data: yData
        }]
      }

      this.echart.setOption(option)
    })
  }

  resize () {
    if (this.echart) {
      this.echart.resize()
    }
  }
}
